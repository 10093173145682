import React, { useState } from 'react';
// import * as FaIcons from 'react-icons/fa';
// import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
// import { SidebarData } from './Sidebardata';
import './Navbar.css';
// import { IconContext } from 'react-icons';


function NavbarTop() {

    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);
  return (
    <>
        {/* <IconContext.Provider value={{ color:'grey' }}> */}
       

        <nav className="navbar-top"  > 

                    <ul className="navbar-top-container">
                        
                        
                     <li className=""><Link to='/' >  Home </Link> </li>
                        <li className=""><Link to='/about' >  About </Link> </li>
                        <li className=""><Link to='/skyleaf-glass' >  skyleaf Glass </Link> </li>

                        {/* <li className=""><Link to='/open-positions' > Open Positions</Link> </li> */}
                        <li className=""><Link to='/get-hired' > Get Hired </Link> </li>
                        
                        <li className=""><Link to='/pride-dei' > Pride DE&I </Link> </li>
                        
                        <li className=""><Link to='/people' > People </Link> </li>
                        <li className=""><Link to='/service' > Services </Link> </li>
                        <li className=""><Link to='/contact' > Contact </Link> </li>


                        {/* <li className=""><Link to='/post-jobs' > Post Jobs </Link> </li> */}
                        {/* <li className=""><Link to='/post-jobs' > Post Jobs </Link> </li> */}
                        {/* <li className=""><Link to='/post-jobs' > Post Jobs </Link> </li> */}
                        {/* <li className=""><Link to='/post-jobs' > Post Jobs </Link> </li> */}
                        {/* <li className=""><Link to='/post-jobs' > Post Jobs </Link> </li> */}
                        {/* <li className=""><Link to='/post-jobs' > Post Jobs </Link> </li> */}
                        {/* <li className=""><Link to='/post-jobs' > Post Jobs </Link> </li> */}
                        
                        
                        

                    </ul>
                    
        </nav>
    

       
        {/* </IconContext.Provider> */}
    </>
      
    
  )
}

export default NavbarTop;
