import React from 'react' 
import '../../App.css';
import './Styles.css';
import BlogDispHome from '../SkyleafThinks/BlogDispHome';
import SubscribeBtHome from '../Subscribe/SubscribeBtHome';
import HomeSecIconSGlass from './Images/HomeSecIconSGlass.png';
import {Link} from 'react-router-dom';
import ImgSlideBanner from './Images/ImgSlideBanner.png'


import HomeSlider2 from './HomeSlider2';

import HomeSliderCustom1 from './HomeSliderCustom';

import AOS from 'aos';
import 'aos/dist/aos.css';


// AOS.init();



function Home() {
  return (
    <div className=" ">

        
        <HomeSliderCustom1 />

          
        
        {/* <div className="banner-home-slider">
               <div> 
                 

                  <div className='ImgSlideBannerDiv'> <img className='ImgSlideBanner' src={ImgSlideBanner} /></div>
                  
                  <p className='Head'> what drives us?  </p> 

                  <Link to='/purpose'> <p className='PurposeButton'> the skyleaf purpose </p> </Link>

                </div>

        </div> */}
        
        {/* <HomeSlider2 /> */}
      
        
      

        

       

        
        {/* <div className="banner-home-skyleaf-openpos">
             <div> 
                <p className='HeadSecOpenPos'> Apply for Open Positions  </p>
                <p className="SubHeadSecOpenPos"> Partnering with corporates to help them hire talent faster and operate at the speed of thought </p>
                
                <Link to="/open-positions" >  <p className='HomeOpenPosBt'> View Open Positions </p> </Link>
              </div>
          
        </div> */}


        {/* <BlogDispHome /> */}

        

        <div className="banner-home-subscribe">
              <div> 
              <h2 className='HeadSubscribeHome'> Subscribe to the latest updates from skyleaf on Recruitment, Human Workforce and more.  </h2>
              <SubscribeBtHome />  
              </div>   
        </div>

        <div className="banner-home-fraudalert">
              <div> 
                  <p className='HeadFraudAlert'> 
                  <bold> Fraud Alert: </bold> This is a notice in public interest, all private sector and public sector organisations 
                  and in the employees/ workforce/ candidate's interest, that there are rampant impersonations or individuals commiting fradulent activities, 
                  who might claim to be part of 'skyleaf' or 'skyleaf consultants' or any "business” or "company" or “client” we work with. Please make your thorough enquiry before making any decision/s or transactions with such illegal entities, as a job seeker or any corporate entity. skyleaf shall not be responsible/ liable for any such incident/s thereof.
                  </p>
              
              </div>              
        </div>


        


      
    </div>
  )
}



export default Home
