import React from 'react'
import './Styles.css'
import AboutPeopleBt from './AboutPeopleBt'

function AboutPeople() {
  return (
    <>


<div className='AboutPeopleWidget' data-aos='fade-up' data-aos-duration='1400'> 
                
                <p className=''> skyleaf Culture </p>

                <p className='AboutCultDescTxt'> 
                Our culture is driven by collaboration, valuing human impact on business, 
                performance and exploring the human potential in each one on the team, 
                working with skyleaf or candidates whom we recruit for our business clients.

                <br /> 
                Constantly learning how people perform, what motivates them, how to manage the 
                fast changing dynamic demands of business with people, where they are in their 
                performance readiness, all this defines us and makes us better every time we take a 
                look at a candidate.

                
                 </p>

          
                
                {/* <AboutPeopleBt /> */}
                


            </div>


    </>

  )
}

export default AboutPeople