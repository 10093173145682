import React from 'react'
import './Styles.css'


function Contact() {
  return (
    <div className="contact ContainerDiv">

        <p className='ContactPg '> <strong>  skyleaf consultants  </strong> </p>

        <p className=' ContactPg '> 
                Corp. Office: 713, 7th Floor, Devika Towers, 
                Nehru Place,
                Near Tata Motors Showroom, New Delhi 110019, India </p>

        {/* <p className=' ContactPg '> Reg. Address: Faridabad, Haryana, India </p> */}
        
        

        <p className=' ContactPg '> e: <strong> contactus@skyleafglobal.com </strong> <br /> m: +91 <strong> 96459 97459 / +91 96545 47915  </strong> </p>
        
        
        <p className=' ContactPg '>  </p> 
        

      
    </div>
  )
}



export default Contact
