import React from 'react'
import './Styles.css'
import { Link } from 'react-router-dom'

function EnrollButton() {
  return (
    <>
       
        
          {/* <Link to="/enroll" className='EnrollButton' style={{ marginTop:'4rem', paddingTop:'1rem', paddingBottom:'1rem', paddingRight:'0.5rem', lineHeight:'rem', }}> enroll </Link> */}
          <Link to="/enroll" className='EnrollButton' > enroll </Link>
      
      
    </>
  )
}



export default EnrollButton