import React from 'react'
import './Styles.css'

function AboutRegions() {
  return (
    <>

            <div className='AboutRegionsWidget' data-aos='fade-up' data-aos-duration='1400'> 
                
            <p className=''> Regions we cover </p> <br />

                <div className='RegionsCardDiv'> 
                    <div className='RegionCard'> 
                    <p className='RegionCardTxt'> India </p>
                    </div>

                    <div className='RegionCard'> 
                    <p className='RegionCardTxt'> Europe </p>
                    </div>

                    <div className='RegionCard'> 
                    <p className='RegionCardTxt'> Latin America </p>
                     
                    </div>

                    <div className='RegionCard'> 
                    <p className='RegionCardTxt'> Middle East </p>
                    </div>

                    <div className='RegionCard'>
                    <p className='RegionCardTxt'> USA </p>
                    </div>

                    <div className='RegionCard'> 
                    <p className='RegionCardTxt'> APAC </p>
                    </div>

                </div>
                
                

            </div>




    </>
  )
}

export default AboutRegions