import React from 'react'

function HRConsulting() {
  return (
    <div className="hr-consulting">

        <h1> HRConsulting </h1>
      
    </div>
  )
}



export default HRConsulting
