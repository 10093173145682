import React from 'react'
import '../Home/Styles.css';
import './Styles.css';
import {Link} from 'react-router-dom';

function SubscribeBtHome() {
  return (
    <> 
    
      <Link to="/"> <div className=" SubscribeBtHome ">
          Subscribe
      </div>
      </Link>

    </>
  )
}



export default SubscribeBtHome
