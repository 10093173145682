import React from 'react'
import './Styles.css'
import Icon1 from './Images/Icon1.png'
import Icon2 from './Images/Icon2.png'
import Icon3 from './Images/Icon3.png'
import Icon4 from './Images/Icon4.png'
import Icon5 from './Images/Icon5.png'



function GetHiredIcons() {
  return (
    <>
        <div className='GetHiredIconsSec'>  
            <div className='SingleIcon IconOne'> 
                <p> skyleaf Glass evaluates your skill set and matches with the right opportunity</p>
                <div className='SingleIconDiv'> <img src={Icon1} />  </div>
            </div>
            <div className='SingleIcon IconTwo'> 
            <p> We match you to colleagues and companies most synergetic to both </p>
            <div className='SingleIconDiv'> <img src={Icon2} /> </div>
            </div>
            <div className='SingleIcon IconOne'> 
            <p> Wide Opportunity Pool across different industries </p>
            <div className='SingleIconDiv'> <img src={Icon3} /> </div>
            </div>
            <div className='SingleIcon IconTwo'> 
            <p> Ensured Privacy at all steps for candidates during the process </p>
            <div className='SingleIconDiv'> <img src={Icon4} /> </div>
            </div>
            <div className='SingleIcon IconOne'>
            <p> Get Matched with the right opportunity in 7 Days </p>
            <div className='SingleIconDiv'> <img src={Icon5} /> </div>
            </div>



        </div>
    
    </>
  )
}



export default GetHiredIcons