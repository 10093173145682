import React from 'react'
import AboutJourney from './AboutJourney'
import AboutPeopleBt from './AboutPeopleBt'
import AboutRegions from './AboutRegions';
import AboutPeople from './AboutPeople'


function AboutWidgets() {
  return (
    

    <>
        <div className='AllWidgets'> 

            <AboutJourney /> 


            <AboutRegions />



            <AboutPeople />

        
        </div>
        

    </>
  )
}

export default AboutWidgets