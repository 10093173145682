
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';


import Home from './pages/Home/Home.js';
import About from './pages/About/About.js';
import PeopleIdentity from './pages/PeopleIdentity/PeopleIdentity.js';
import ExecutiveSearch from './pages/ExecutiveSearch/ExecutiveSearch.js';
import HRConsulting from './pages/HRConsulting/HRConsulting.js';
import WorkWithSkyleaf from './pages/WorkWithSkyleaf/Work with skyleaf.js';
import People from './pages/Team/People.js';
import Pride from "./pages/SkyleafPrideLGBTQAI/Pride.js";

import SkyleafThinksBlogPg from './pages/SkyleafThinks/SkyleafThinksBlogPg';
import Contact from './pages/Contact/Contact.js';
import Login from './pages/Login/Login.js';
import ForgotPass from "./pages/Login/ForgotPass";
import OpenPositions from './pages/OpenPositions/OpenPositions';
import PostJobs from './pages/PostJobs/PostJobs';
import GetHired from './pages/GetHired/GetHired';
import SkyleafGlass from './pages/ExecutiveSearch/SkyleafGlass';

import Header from './components/Header';
import Footer from './components/Footer/Footer.js';

import EnrollButton from './pages/GetHired/EnrollButton';
import Purpose from './pages/Purpose/Purpose';
import Blog1Pg from './pages/SkyleafThinks/SingleBlogsAll/Blog1Pg';
import Blog2Pg from './pages/SkyleafThinks/SingleBlogsAll/Blog2Pg';
import Blog3Pg from './pages/SkyleafThinks/SingleBlogsAll/Blog3Pg';
import Blog4Pg from './pages/SkyleafThinks/SingleBlogsAll/Blog4Pg';



import AOS from 'aos';
import 'aos/dist/aos.css';



function App() {
  return (
    <div className="App">
      
      <Router>
          
          <Header />
          
          
         
          <Routes> 
            <Route exact path='/' element={<Home />} />  
            <Route path='/about'  element ={<About />} />  
            <Route path='/people-identity'  element ={<PeopleIdentity />} />  
            <Route path='/executive-search'  element ={<ExecutiveSearch />} />  
            <Route path='/hr-consulting'  element ={<HRConsulting />} />  
            {/* <Route path='/work-with-skyleaf'  element ={<WorkWithSkyleaf />} />   */}
            
            <Route path='/people'  element ={<People />} />  
            <Route path='/pride-dei'  element ={<Pride />} />  


            <Route path='/skyleaf-thinks'  element ={<SkyleafThinksBlogPg />} />  
            <Route path='/contact'  element ={<Contact />} />  
            <Route path='/login'  element ={<Login />} />  
            <Route path='/forgot-password'  element ={<ForgotPass />} />  
            

            <Route path='/open-positions'  element ={<OpenPositions />} />
            <Route path='/get-hired'  element ={<GetHired />} />
            <Route path='/skyleaf-glass'  element ={<SkyleafGlass />} />

            <Route path='/post-jobs'  element ={<PostJobs />} />  
            <Route path='/enroll'  element ={<EnrollButton />} /> 
            <Route path='/purpose'  element ={<Purpose />} /> 

            {/* <Route path='/terms'  element ="" />  */}
            {/* <Route path='/privacy'  element ="" />  */}
            
            


            <Route path='/blog1pg'  element ={<Blog1Pg />} /> 
            <Route path='/blog2pg'  element ={<Blog2Pg />} /> 
            <Route path='/blog3pg'  element ={<Blog3Pg />} /> 
            <Route path='/blog4pg'  element ={<Blog4Pg />} /> 


            
            
            

          </Routes>

      </Router>

      {/* <Footer /> */}
      
      
      
      
    </div>
  );
}

export default App;
