import React from 'react'


function Blog1Pg() {
  return (

    <> 

    <div className=''> Blog1Pg </div>
    
    
    
    </>
    
  )
}

export default Blog1Pg