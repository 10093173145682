// import * as FaIcons from 'react-icons/fa';
// import * as AiIcons from 'react-icons/ai';
// import * as IoIcons from 'react-icons/io';


 export const SidebarData = [
    {
        // title: 'Home',
        // path: '/',
        // // icon: <AiIcons.AiFillHome /> ,
        // cName: 'nav-text',
    },

    
    {
        title: 'About skyleaf',
        path: '/about',
        // icon: <FaIcons.FaArrowAltCircleDown /> ,
        cName: 'nav-text',
    },

    {
        title: 'skyleaf Glass',
        path: '/skyleaf-glass',
        // icon: <FaIcons.FaArrowAltCircleDown /> ,
        cName: 'nav-text',
    },

    {
        title: 'Open Positions',
        path: '/open-positions',
        // icon: <FaIcons.FaArrowAltCircleDown /> ,
        cName: 'nav-text',
    },


   
    // {
        // title: 'the people identity',
        // path: '/people-identity',
        // icon: <IoIcons.IoIosAlert /> ,
        // cName: 'nav-text',
    // },

   

    {
        title: 'GetHired',
        path: '/get-hired',
        // icon: <FaIcons.FaArrowAltCircleDown /> ,
        cName: 'nav-text',
    },

    {
        title: 'Pride DE&I',
        path: '/pride-dei',
        // icon: <AiIcons.AiFillHome /> ,
        cName: 'nav-text',
    },




    // {
    //     title: 'Executive Search',
    //     path: '/executive-search',
    //     // icon: <AiIcons.AiFillHome /> ,
    //     cName: 'nav-text',
    // },
    // {
    //     title: 'HR consulting ',
    //     path: '/hr-consulting',
    //     // icon: <AiIcons.AiFillHome /> ,
    //     cName: 'nav-text',
    // },
    {
        title: 'People at skyleaf',
        path: '/people',
        // icon: <AiIcons.AiFillHome /> ,
        cName: 'nav-text',
    },

    {
        title: 'skyleaf Purpose',
        path: '/purpose',
        // icon: <FaIcons.FaArrowAltCircleDown /> ,
        cName: 'nav-text',
    },

    
    
    {
        title: 'Work with skyleaf',
        path: '/work-with-skyleaf',
        // icon: <AiIcons.AiFillHome /> ,
        cName: 'nav-text',
    },
    {
        title: 'skyleaf thinks',
        path: '/skyleaf-thinks',
        // icon: <AiIcons.AiFillHome /> ,
        cName: 'nav-text',
    },
    {
        title: 'Contact',
        path: '/contact',
        // icon: <AiIcons.AiFillHome /> ,
        cName: 'nav-text',
    },
    // {
    //     title: 'Login',
    //     path: '/login',
    //     // icon: <AiIcons.AiFillHome /> ,
    //     cName: 'nav-text',
    // },

   


    

]



