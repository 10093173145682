import React from 'react'
import SkyleafGlassLogo from './Images/SkyleafGlassLogo1.png';

import './Styles.css'
import sGlassCorp1 from './Images/sGlassCorp1.png'
import sGlassCorp2 from './Images/sGlassCorp2.png'
import sGlassCorp3 from './Images/sGlassCorp3.png'

import AOS from 'aos';
import 'aos/dist/aos.css';


function SkyleafGlass() {
  return (

    <>

    <div className="BgSkyleafGlassPgBanner"> 

        <p className='HeadSkyleafGlassPg'> 

        </p>

        {/* <p className='SubHeadGetHiredPg'> Get the right opportunities in 7 Days </p> */}

        
        <div className='SkyleafGlassLogo' data-aos='fade-in' data-aos-duration='1400' >   
        <img className=' ' src={SkyleafGlassLogo} />
        </div>

        {/* <EnrollButton  />   */}
        

    {/* </div> */}

    {/* <div className="BgSkyleafGlassPgBanner"> */}

        <p className='HeadSkyleafGlassPg'> 

        skyleaf Glass is the unique and human approach to identify different layers of talent in individuals. 
        Our acquired learnings since 2010, is now a directional framework for us to deliver precise, faster and smarter recruitment outcomes.
        </p>

        {/* <p className='SubHeadGetHiredPg'> Get the right opportunities in 7 Days </p> */}
       
        {/* <div className='SkyleafGlassLogo'>   
        <img className=' ' src={SkyleafGlassLogo} />
        </div> */}

        {/* <EnrollButton  />   */}
        

    </div>


    <div className=" sGlassFeaturesDiv">

          <div className='LeftSide'> 
              <p className=' HeadFeaturesSG '> 
              skyleaf Glass for corporates
              {/* See though Candidates clearly to Leverage Smarter Decision Making  */}
              </p>
              {/* <p className='SubHeadFeatures'> skyleaf Glass for corporates </p> */}

          </div>
        
          <div className='RightSide ' data-aos='fade-up' data-aos-duration='800' >

              <div className='featuresBlock'> 
                
                <div className=" sGlassCorpDiv "  data-aos='fade-up' data-aos-duration='800' >  
                 <img className=' sGlassCorp' src={sGlassCorp1} /> </div>

                <p className='BlockHead'>  See through your candidates </p> 
                
                <p className='BlockSubhead'> Through our extensive intuitive, personalised and extensive technology driven processes know more than just a resume full of pages to hire the real people you know in advance.
                </p>
              </div>


              <div className='featuresBlock'> 
              <div className=" sGlassCorpDiv " data-aos='fade-up' data-aos-duration='800' >  
                <img className=' sGlassCorp ' src={sGlassCorp2} /> </div>
                <p className='BlockHead'>  Be skyleaf Predictable </p> 
                
                <p className='BlockSubhead'> 
                      Work with high predictablility when you use skyleaf Glass for your business recruitment, with lesser time to operations with faster and larger evaluation capabilities.
                </p>
              </div>


              <div className='featuresBlock'> 
              <div className=" sGlassCorpDiv " data-aos='fade-up' data-aos-duration='800'>  
                <img className=' sGlassCorp ' src={sGlassCorp3} /> </div>
                <p className='BlockHead'> Metric driven recruitment process </p> 
                
                <p className='BlockSubhead'> 
                  It is not just about candidates, Match and define description of your positions with better metrics, skill sets and skyleaf Glass paramater points making your hiring process, expectations utmost clear.
                </p>

              </div>










          </div>

    </div>



    {/* <GetHiredIcons /> */}

    {/* <div className='SGlassWorks'> 
      <p className='HeadSGlassWorks'> How it works? </p>
      
      <a href="/sglass-know-more"> <p className='SGlassKnowMoreBt'> know more</p> </a> 
      

    </div> */}


    
    </>
  )
}



export default SkyleafGlass
