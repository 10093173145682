import React from 'react'

function PostJobs() {
  return (
    <>
    <div className="post-jobs">
       
       <h1> Post your jobs </h1>
       

    </div>
    {/* <p> with Skyleaf Glass as an employer </p> */}
    </>
  )
}

export default PostJobs
