import React from 'react'
import GetHiredIcon from './Images/GetHiredIcon.png';
import './Styles.css'
import EnrollButton from './EnrollButton';
import GetHiredIcons from './GetHiredIcons';
import {Link} from 'react-router-dom'


function GetHired() {
  return (

    <>
    <div className="BgGetHiredPgBanner" >

        <p className='HeadGetHiredPg'  data-aos='fade-up'  data-aos-duration='1200' > 
          GetHired with skyleaf Glass </p>
        <p className='SubHeadGetHiredPg' data-aos='fade-up'  data-aos-duration='1200'> 
          Get the right opportunity in 7 Days </p>

        {/* <EnrollButton  />   */}

        <div className='GetHiredIconCont' data-aos='fade-in'  data-aos-duration='1800'> 
          <img className='GetHiredIcon' src={GetHiredIcon} /> </div>

      

    </div>

    <GetHiredIcons />
      

    {/* <div className='SGlassWorks'> 
      <p className='HeadSGlassWorks'> How it works? </p>
      <div>       
        <Link to="/skyleaf-glass-for-candidates" className='SGlassKnowMoreBt'> know more  </Link> 
      </div> */}

      {/* <img src={} /> */}


    {/* </div> */}
    
    </>
  )
}



export default GetHired
