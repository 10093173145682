import React from 'react'
import './Styles.css'

function OpenPositions() {
  return (

    <>
    
         <div className="BgOpenPosPg">
            <p className='HeadOpenPosPg' > Open Positions </p>

            <div className=" ">

              
              
               </div>


           
      
        </div>
  </>

  )
}



export default OpenPositions;
