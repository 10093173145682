import React from 'react'

function PeopleIdentity() {
  return (
    <div className="people-identity">

        <h1> People Identity </h1>
      
    </div>
  )
}



export default PeopleIdentity
