import React from 'react'
import './Styles.css'
import ForgotPassBtSubmit from './ForgotPassBtSubmit'

function ForgotPass() {
  return (
    <> 
    

         <div className='spacerLogin'> </div>
         <div className="LoginPgBg LoginPgCont">

           <p className='ForgotPassPgHead '>  Login through OTP </p>
           <p className='ForgotPassPgSubHead '>  Enter your registered email or mobile number </p>
          

            <ForgotPassBtSubmit />
          
      </div>


    </>
  )
}

export default ForgotPass