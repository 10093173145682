import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import Navbar from './Navbar/Navbar'
import NavbarTop from './Navbar/NavbarTop'
import logo from '../logo/skyleaf-logo.svg';
import './Navbar/Navbar.css';

import AOS from 'aos';
import 'aos/dist/aos.css';



function Header() {
    

  return (
    <div>
    <div className="Header"  data-aos='slide-down' data-aos-duration='1000' >


        <div>
        <Link to='/' > <img className="skyleaf-logo" src={logo} alt="skyleaf logo" />  </Link>
        </div>

        <NavbarTop />
        
            
      
    </div>

    <Navbar />
    </div>
  )
}



export default Header
