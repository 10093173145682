import React from 'react'
import './Styles.css';


function SkyleafThinksBlogPg() {
  return (

    <>

    <div className="skyleaf-thinks">

        <h1> skyleaf Thinks </h1>

        
      
    </div>
    
      <div > Blog Page for skyleaf </div>

    </>
  )
}



export default SkyleafThinksBlogPg
