import React from 'react'


function AboutJourney() {
  return (
    <> 
    
        <div className='AboutJourneyWidget' data-aos='fade-in' data-aos-duration='1400'> 
            
            <p className=''> skyleaf Journey </p> <br />

            <div className='JourneysDiv'>  

                <div className='JourneyCard'> 
                <p className='CardTime'> 2023</p>
                <p className='CardTopic'> 
                  Launched Pride DE&I vertical to enable universal inclusion </p> 
                </div>

                <div className='JourneyCard'>
                <p className='CardTime'> 2021</p>
                <p className='CardTopic'> 
                  Hiring Global Talent in Major Continents/Markets 
                </p> 
                </div>

                <div className='JourneyCard'>
                <p className='CardTime'> 2019</p>
                <p className='CardTopic'> 
                Entered new products that include HR Tech and Advisory Services
                </p> 
                </div>

                <div className='JourneyCard'>  
                <p className='CardTime'> 2017</p>
                <p className='CardTopic'> Opened Offices in Bangalore and Dehradun <br /> 
                RPO process started <br />
                Clients Retained for 4+ yrs: 10 <br /> 
                Clients Served: 30 <br />
                Team Strength: 42 
                 </p> 
                </div>

                <div className='JourneyCard'>  
                <p className='CardTime'> 2015</p>
                <p className='CardTopic'> 
                Opened New Delivery Center in South Delhi <br />
                Clients Retained for 3+ yrs: 7 <br /> 
                Clients Served: 15 <br />
                Team Strength: 16 
                </p> 
                </div>

                <div className='JourneyCard'>  
                <p className='CardTime'> 2013</p>
                <p className='CardTopic'> 
                Successfully delivered Executive Search mandates <br />
                Clients Retained for 2+ yrs: 5 <br /> 
                Team Strength: 16
                 </p> 
                </div>

                <div className='JourneyCard'>  
                <p className='CardTime'> 2011</p>
                <p className='CardTopic'> Team Strength: 10 <br /> Clients Served:8  </p> 
                </div>

                <div className='JourneyCard'>  
                <p className='CardTime'> 2009</p>
                <p className='CardTopic'> skyleaf's Inception with 2 employees </p> 
                </div>

            
            </div> 


            

        </div>

    </>
  )
}

export default AboutJourney