import React from 'react'
import './Styles.css'
import PridePgBannerIcon from './Images/PridePgBannerIcon.png';
import AboutPgIcons from './AboutPgIcons';
import PeopleIdentAni from './Images/PeopleIdentAni.gif';
import ThePeopleIdentBt from './ThePeopleIdentBt';
import AboutWidgets from './AboutWidgets';

import AOS from 'aos';
import 'aos/dist/aos.css';


function Pride() {
  return (

    <>
    <div className="BgAboutPgBanner">



       <p className='HeadPridePg' data-aos='fade-up' data-aos-duration='800'> 
          Equality and Pride for All <br />
          starts from the Workplace
          </p> 

        <div className='PridePgIconDiv' data-aos='fade' data-aos-duration='1000' > 
          <img className='PridePgIcon' src={PridePgBannerIcon} />       
           </div>
        
        
        {/* <p className='SubHeadAboutPg'>  </p> */}


    </div>

    {/* <AboutPgIcons /> */}


    {/* <div className='AbtPgPplIdentSec' data-aos='fade-up' data-aos-duration='1400'  >
      <p className='PeopleIdentSecHead' > 
        The People Identity
      </p>

      <div className='ImgPplDiv' data-aos='fade-in' data-aos-duration='3000'>
        <img className='ImgPplIdent' src={PeopleIdentAni} />  </div>

      <p className='PeopleIdentSecSubHead'>
        Our identity and culture emanates from People. People made of Individuals, Individuals made of Character, Character defining the Talent, the Talent which drives our Identity
      </p>

    
    
    </div>  */}


    {/* <div className='AboutWidgetSec' data-aos='fade-up' data-aos-duration='3200'> 

      <AboutWidgets /> 

    </div> */}

    {/* <div className='AboutIndustrySec' data-aos='fade-up' data-aos-duration='1400'>
        <p className='AboutIndustrySecHead'> Industries Served </p>
        

        <div className='IndustryDiv'>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> LOGISTICS </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> ITeS </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> AUTO </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> INTERNET </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> EDUCATION </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> FMCG </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> KPO </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> ENTERTAINMENT</p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> FMCD </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> EPC </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400'> <p className='IndName'> HEALTHCARE </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> eCOMMERCE </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> IoT </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> MEDIA </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> BFSI </p> </div>
                  
          </div> 
          
    </div> */}


    
    </>
  )
}



export default Pride
