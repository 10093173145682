import React from 'react';
import './Styles.css';
import Icon1 from './Images/IconOne.png'
import Icon2 from './Images/IconTwo.png'
import Icon3 from './Images/IconThree.png'
import Icon4 from './Images/IconFour.png'
import Icon5 from './Images/IconFive.png'
import Icon6 from './Images/IconSix.png'
import Icon7 from './Images/IconSeven.png'
import Icon8 from './Images/IconEight.png'




function AboutPgIcons() {
  return (

    <>     
      <div className='IconsContDiv'>
        <div className='HeadFeatures' data-aos='fade' data-aos-duration='1400' > The skyleaf Promise </div>

        <div className='AboutPgIconsCont' data-aos='fade-up' data-aos-duration='1000' > 

              <div className='SingleIconCard IconOdd' data-aos='fade-up' data-aos-duration='1400' > 
                
                <div className='IconDiv'>  <img src={Icon1} /> </div>
                <p> Lowest Hiring TAT</p>
              </div>
              <div className='SingleIconCard IconEven' data-aos='fade-up' data-aos-duration='1400'> 
              
              <div className='IconDiv'>   <img src={Icon2} />  </div>
              <p> Serving 5 Continents </p>
              </div>
              <div className='SingleIconCard IconOdd' data-aos='fade-up' data-aos-duration='1400'> 
              
              <div className='IconDiv'>   <img src={Icon3} />  </div>
              <p> Reliable Support </p>
              </div>
              <div className='SingleIconCard IconEven' data-aos='fade-up' data-aos-duration='1400'> 
              
              <div className='IconDiv'>  <img src={Icon4} />  </div>
              <p> Experienced Experts </p>
              </div>
              <div className='SingleIconCard IconOdd' data-aos='fade-up' data-aos-duration='1400'>
            
              <div className='IconDiv'> <img src={Icon5} />  </div>
              <p> Value Added Services </p>
              </div>
              <div className='SingleIconCard IconEven' data-aos='fade-up' data-aos-duration='1400'>
              
              <div className='IconDiv'> <img src={Icon6} />  </div>
              <p> On-demand HR Consulting </p>
              </div>

              <div className='SingleIconCard IconOdd' data-aos='fade-up' data-aos-duration='1400'>
              
              <div className='IconDiv'>  <img src={Icon7} />  </div>
              {/* <p> Value-Oriented to Advanced Service Capabilities </p>  */}
              <p> Ai Driven Talent Evaluation  </p> 
              </div>


              <div className='SingleIconCard IconEven' data-aos='fade-up' data-aos-duration='1400'>
            
              <div className='IconDiv'> <img src={Icon8} />  </div>
              <p> Smooth Upscale & Downscale </p> 
              </div>
              

        </div>


      </div>


      </>

  )

}

export default AboutPgIcons




