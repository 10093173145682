import React from 'react'
import './Styles.css'
import { Link } from 'react-router-dom'

function ForgotPassBtSubmit() {
  return (
    <> 
        <Link to="">  <div className=' LoginPgBt' > submit </div> </Link>

    </>
    
  )
}

export default ForgotPassBtSubmit