import React, {useEffect} from 'react'
import {Link} from 'react-router-dom';
import './Styles.css'
import PurposePgIconMain from './Images/PurposePgIconMain.png'
import PurposePgIcon1 from './Images/PurposePgIcon1.png'
import PurposePgIcon2 from './Images/PurposePgIcon2.png'
import PurposePgIcon3 from './Images/PurposePgIcon3.png'

import AOS from 'aos';
import 'aos/dist/aos.css';



AOS.init();

function Purpose() {
  
  // useEffect(()=>{
  //   AOS.init({duration:1000});
  // }, []
  // );
  

  return (

    <>
    <div className="BgPurposeBanner" data-aos='fade-in' data-aos-duration='1800'>

        {/* <p className='HeadPurposePg'> 
        We identify, recruit and help nurture human potential with high predictability
        </p> */}

        <p className='HeadPurposePg'>
            On our constant search for the human potential
        </p>

        <p className='SubHeadPurposePg'> the purpose </p>
        
         <div className='PurposePgIconMainDiv'>   
         
            <div className='ImgMainDiv'  data-aos='zoom-in'  data-aos-duration='2000' > 
               <img  className='ImgMain' src={PurposePgIconMain} /> </div>

            <p className='DescPurposePg'>
                    skyleaf is committed to the talent available locally and human resource industry while 
                    delivering efficient outcomes for businesses to run and scale with dynamic needs. 
                    We invest in developing better ways to identify, recruit, retain and nurture talent. 
                    Since 2010, the purpose has become more clearer and bigger to make a dent in the universe of talent acquisition, solving the pains of different stakeholders who are an important part of the globally connected economies.
            </p>
         
         </div>

      
        {/* <p className='DescPurposePg'> 
                skyleaf is committed to the talent and industry while delivering efficient outcomes for businesses to run and scale with dynamic needs. We invest in developing better ways to identify, recruit, retain and nurture talent. Since 2011, the purpuse has become more clearer and bigger to make a dent in the universe of talent acquisition, solving the pains of different stakeholders who are an important part of the globally connected economies.
        </p> */}


    </div>

    <div className='SecPurpose1'  data-aos='fade-up' data-aos-duration='2000' > 
        <p className='HeadSecPurpose1'> 
            We bring together the professional community with a common goal to grow and learn
        </p>
        
        {/* <Link to="#"> <p className='SubHeadSecPurpose1'> skyleaf Sense</p> </Link> */}
        <Link to="#" className='SubHeadSecPurpose1'> skyleaf Sense </Link> 

        <div className='Icon1MainDiv'>

         <div className='Icon1Div' data-aos='zoom-in'  data-aos-duration='2000'>  <img className='Icon1' src={PurposePgIcon1} /> </div>

          <p className='DescIcons'> 
             Dummy text: skyleaf is committed to the talent and industry while delivering efficient outcomes for businesses to run and scale with dynamic needs. 
          </p>

        </div>

    </div>  

    <div className='SecPurpose2'  data-aos='fade-up' data-aos-duration='2000' >
        <p className='HeadSecPurpose2'>
          Standing for an inclusive society, starting with workplace equality
        </p>

        <Link to="/pride-dei" className='SubHeadSecPurpose2'> skyleaf Pride  </Link>
        
        <div className='Icon2MainDiv'>   
          <div className='Icon2Div' data-aos='zoom-in'  data-aos-duration='2000' > <img className='Icon2' src={PurposePgIcon2} /> </div>

          <p className='DescIcons'> 
            Dummy text: skyleaf is committed to the talent and industry while delivering efficient outcomes for businesses to run and scale with dynamic needs. 
          </p>

        </div>

    </div>


    <div className='SecPurpose3'  data-aos='fade-up' data-aos-duration='1500' >
        <p className='HeadSecPurpose3'>
          Continued careers even while
          upscale and downscale
          
          {/* the pursuit of fulfilling careers and org. talent goals with flexible workforce size */}
        </p>
        <Link to="#" className='SubHeadSecPurpose3'> Support in uncertainty </Link>

        <div className='Icon3MainDiv'>   
        <div className='Icon3Div' data-aos='zoom-in'  data-aos-duration='2000'>  <img className='Icon3' src={PurposePgIcon3} /> </div>

          <p className='DescIcons'> 
                    Dummy text: skyleaf is committed to the talent and industry while delivering efficient outcomes for businesses to run and scale with dynamic needs. 
          </p>

        </div>

    </div>

  
       
    
    </>
  )
}



export default Purpose
