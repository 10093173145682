import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


// function disableBodyScroll(){
//   const element = document.querySelector("#appBody");
  
//   if (nav-menu.active == true) {
//     element.classList.add("stop-scroll");
//   }

//  }
 
//  function enableBodyScroll(){
//   const element = document.querySelector("#appBody");
//   if (nav-menu.active == false) {

//     element.classList.remove("stop-scroll");

//   }
  
//  }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
