import React from 'react'
import './Styles.css'
import AboutPgIcon from './Images/AboutPgIcon.png';
import AboutPgIcons from './AboutPgIcons';
import PeopleIdentAni from './Images/PeopleIdentAni.gif';
import ThePeopleIdentBt from './ThePeopleIdentBt';
import AboutWidgets from './AboutWidgets';

import AOS from 'aos';
import 'aos/dist/aos.css';


function About() {
  return (

    <>
    <div className="BgAboutPgBanner">

        <div className='AboutPgIconDiv' data-aos='fade' data-aos-duration='1000' > 
          <img className='AboutPgIcon' src={AboutPgIcon} />        </div>
        
        <p className='HeadAboutPg' data-aos='fade-up' data-aos-duration='800'> 
         Identify potential talent with skyleaf's intuitive process and achieve predictable business outcomes </p> 
        {/* <p className='SubHeadAboutPg'>  </p> */}


    </div>

    <AboutPgIcons />



    <div className='AboutWidgetSec' data-aos='fade-up' data-aos-duration='3200'> 

      <AboutWidgets /> 

    </div>

    <div className='AboutIndustrySec' data-aos='fade-up' data-aos-duration='1400'>
        <p className='AboutIndustrySecHead'> Industries Served </p>
        

        <div className='IndustryDiv'>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> LOGISTICS </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> ITeS </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> AUTO </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> INTERNET </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> EDUCATION </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> FMCG </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> KPO </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> ENTERTAINMENT</p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> FMCD </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> EPC </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400'> <p className='IndName'> HEALTHCARE </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> eCOMMERCE </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> IoT </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> MEDIA </p> </div>
          <div className='IndSingleDiv' data-aos='fade-up' data-aos-duration='1400' > <p className='IndName'> BFSI </p> </div>
                  
          </div> 
          
    </div>


    <div className='AbtPgPplIdentSec' data-aos='fade-up' data-aos-duration='1400'  >
      <p className='PeopleIdentSecHead' > 
        The People Identity
      </p>

      <div className='ImgPplDiv' data-aos='fade-in' data-aos-duration='3000'>
        <img className='ImgPplIdent' src={PeopleIdentAni} />  </div>

      <p className='PeopleIdentSecSubHead'>
        Our identity and culture emanates from People. People made of Individuals, Individuals made of Character, Character defining the Talent, the Talent which drives our Identity
      </p>

      {/* <div className=''> <img src="" /> </div> */}
      {/* <ThePeopleIdentBt /> */}
    
    </div> 



    
    </>
  )
}



export default About
