import React from "react";
import './Styles.css';
import { Link } from "react-router-dom";

// import ImgSlideBanner1 from './Images/DNABanner.png'

import FirstBannerImg from './Images/FirstBannerImg.png'
import ImgSlideBanner11 from './Images/MazeBanner.png'
import ImgSlideBanner from './Images/ImgSlideBanner.png'
import ImgSlideBanner3 from './Images/ImgSlideBanner3.gif'
import HomeSecIconSGlass from './Images/HomeSecIconSGlass.png';

import AOS from 'aos';
import 'aos/dist/aos.css';


const HomeSliderCustom = () => {

    const containerStyles1 = {

        width: "100%",
        // height: "1680px",
        // margin: " 0 auto",
        // backgroundColor: "grey",
        // zIndex: "-9999",
        // paddingTop: "100px",


    };

    const slideStyle1 = {
        
        height: "780px",
        // backgroundColor: "white",
        // backgroundColor: "#FFF2FF",
        overflow:"hidden",
    

    };

    const slideStyle2 = {
        
        height: "780px",
        backgroundColor: "#FAFAFA",
        // backgroundColor: "#FBFBFB",
        // backgroundColor: "#FFF8F8",
        overflow:"hidden",

    };

    const slideStyle3 = {
        
        height: "780px",
        // backgroundColor: "#F6F6F6",
        backgroundColor: "#FEF9F9",
        backgroundColor: "white",
        overflow:"hidden",

    };

    

    function ChangeTextHomeBnr() {

        return {
            

            


        }
    }
    


 
    return (  

        <>        
        
        <div className="carouselHome " style={containerStyles1}> 
            

            <div className="banner-home-slider SpSlider1" style={slideStyle1} >
                <div className="SingBannerDiv"> 
                    

                    <div className='ImgSlideBannerDiv '  > 
                        
                        <img alt="" className='ImgSlideBanner ' src={FirstBannerImg}  />

                        </div>
                    
                    <div className=' WidthHead'> 

                    <p className='FirstBannerHead' data-aos='fade-up'  data-aos-duration='1200'> 
                    We enable organizations with their search for  
                    
                    
                     the right human talent </p> 

                    <Link to='/about'> 
                
                        <p className='AboutSkyleafButton'  > 

                                About skyleaf </p> 
                                 </Link>
                    </div>
                      
                    

                    </div>

            </div>


             <div className="banner-home-sglass" > 
            
            <div className='HomeSecIconSGlassDiv' >
              <img className='HomeSecIconSGlass' src={HomeSecIconSGlass} /> </div>

            <div 
            // data-aos='fade-in'  data-aos-duration='3000'
            > 
              <p className='HeadSGlass'> Corporates can recruit talent through skyleaf Glass  </p> 
              <p className='SubHeadSGlass'> Partnering with corporates to help them hire talent faster and operate at the speed of thought.</p>
              
              <Link to="/skyleaf-glass"> 
                <p className='HomeSGlassBt'> sGlass for Corporates</p>
              </Link>
                            
            </div>   


        </div>

            


            <div className="banner-home-slider  BgBannerInclusion " style={slideStyle3} >
                <div > 
                    

                    {/* <div className='ImgSlideBannerDiv'> <img className='ImgSlideBanner' src={ImgSlideBanner3} /></div> */}
                    
                    <p className='  SpHead' 
                    // data-aos='fade-up'  data-aos-duration='2000'
                    >  
                        Businesses can Enrich their talent pool with diversity </p> 


                    {/* <Link to='/Pride-DE&I'> <p className='BtPrideDEandI PrideHomeButton'> Pride and DE&I </p> </Link> */}
                    <Link to='/pride-dei' > 
                        <p className='BtPrideDEandI PrideHomeButton' 
                            > Pride DE&I </p> </Link>



                    </div>

            </div>

            <div className="banner-home-slider" style={slideStyle2} >
                <div> 
                    

                    <div className='ImgSlideBannerDiv1 SpDNA' 
                    // data-aos='fade-up'  data-aos-duration='3000'
                    > 
                        <img alt="" className='ImgSlideBanner1 SpDNA1' src={ImgSlideBanner} /></div>
                    
                    <p className='Head' 
                    data-aos='fade-up'  data-aos-duration='4400'
                     > Our purpose enlightens us  </p> 

                    <Link to='/purpose'> <p className='PurposeButton'> skyleaf purpose </p> </Link>

                    </div>

            </div>


        </div>

        </>


    )

};




export default HomeSliderCustom;