import React from 'react'
import './Styles.css'
import { Link } from 'react-router-dom'

function LoginPgBt() {
  return (
    <> 
        <Link to="">  <div className=' LoginPgBt' > Login </div> </Link>

    </>
    
  )
}

export default LoginPgBt