import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
// import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { SidebarData } from './Sidebardata';
import './Navbar.css';
import { IconContext } from 'react-icons';


function Navbar() {

    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);
  return (
    <>
        {/* <IconContext.Provider value={{ color:'grey' }}> */}
        <div className="Navbar" > 

            <Link to="#" className="menu-bars menu-bar-icon-custom">
                <FaIcons.FaBars onClick={showSidebar} />
                
            </Link>
        </div>

        <nav className={ sidebar ? 'nav-menu active' : 'nav-menu' }> 
            <ul className="nav-menu-items" > 
                <li className="navbar-toggle" > 
                    <Link to="#" className='menu-bars toggle-view arrow-right-custom' onClick={showSidebar}>
                        <FaIcons.FaArrowRight />
                    </Link>
                </li>
                {SidebarData.map((item,index) => {
                    return(
                        <li key={index} className={item.cName} >
                            <Link to={item.path}  onClick={showSidebar}> 
                                {item.icon} <span> {item.title} </span> </Link>
                        </li>
                    )

                })}
            </ul>

            <Link className='SideNavLoginBt' to="/login" onClick={showSidebar}>   login </Link>

            <p className="nav-signature"> &#169; Skyleaf Consultants - 2023  </p> 


        </nav> 
        {/* </IconContext.Provider> */}
    </>
      
    
  )
}

export default Navbar;
