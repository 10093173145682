import React from 'react'
import './Styles.css';
import { Link } from 'react-router-dom';




function Footer() {
  return (

    <> 

        <div className='FooterDivs' >

            <div className='FooterLinks' >
                    
                    {/* <Link to="/contact" > Home </Link> */}
      
              
                    {/* <a href="/terms"> Terms </a>
                    <a href="/policy"> Privacy </a>

                    <a href="/home" > <p className='Links' > Home </p> </a>
                                        

                    <a href="/about">  <p className='Links' > About </p> </a>

                    <a href="/purpose">  <p className='Links' > Purpose  </p> </a>
                    <a href="/skyleaf-glass">  <p className='Links' > skyleaf Glass </p> </a>
                    <a href="/open-positions">  <p className='Links' > Open Positions </p> </a>
                    <a href="/get-hired">  <p className='Links' > GetHired </p> </a>
                    
                    <a href="/people">  <p className='Links' > Team </p> </a>
                    <a href="/culture">  <p className='Links' > Culture </p> </a>
                    <a href="/blog">  <p className='Links' > Blog </p> </a>
                    <a href="/contact"> <p className='Links' > Contact </p>  </a> */}
                
                    

            </div>

            <div className='FooterLinks' >
                    

                   

                    {/* <a href="https://www.linkedin.com">  <p className='Links' > Linkedin </p> </a>
                    <a href="https://www.glassdoor.com">  <p className='Links' > Glassdoor </p> </a>  
                    <a href="https://www.twitter.com">  <p className='Links' > twitter </p> </a>

                    <a href="/terms">  <p className='Links' > Terms </p> </a>
                    <a href="/privacy"> <p className='Links' > Privacy </p>  </a> */}
                    

                    

            </div>
            

            {/* <p className="Footer"> &#169; 2023 - skyleaf  </p> */}


        </div>

        
    </>
  )
}

export default Footer