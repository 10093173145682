import React from 'react'
import './Styles.css'
import { Link} from 'react-router-dom'

import LoginPgBt from './LoginPgBt'


function Login() {
  return (

    <> 
      <div className='spacerLogin'> </div>
      <div className="LoginPgBg LoginPgCont" data-aos='fade-up'  data-aos-duration='1200'>

          <p className='LoginPgHead'>  Login  </p>
          <p className='LoginPgSubHead'> Invitations opening soon </p>

            <div className='LoginFormDiv'> 
            <form className=''>
              
              <input className='LoginInput' placeholder='Username or mobile ' type="text" /> <br />
              <input className='LoginInput' placeholder='Password' type="text" />


            </form>
            </div>
         

          <LoginPgBt /> 

          <Link to="#">  <p className='LoginPgForgotPs'>  Forgot Password? </p> </Link>
      </div>

      

    </>
  )
}



export default Login
